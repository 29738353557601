const images = {
  logo: require('./logo.png'),

  dagora: require('./dagora.png'),
  thumbnail: require('./thumbnail.png'),
  thumbnailTablet: require('./thumbnailTablet.png'),
  thumbnailMobile: require('./thumbnailMobile.png'),
  fish1: require('./fish1.png'),
  fish2: require('./fish2.png'),
  fish3: require('./fish3.png'),
  fish4: require('./fish4.png'),
  fish5: require('./fish5.png'),
  cardFont: require('./cardFont.png'),
  cardBack: require('./cardBack.png'),
  meso: require('./city1.png'),
  hadal: require('./city4.png'),
  bathyaizone: require('./city2.png'),
  abysszone: require('./city3.png'),
  bgMeso: require('./bgMeso.png'),
  bgHadal: require('./bgHadal.png'),
  bgBathyaizone: require('./bgBathyaizone.png'),
  bgAbysszone: require('./bgAbysszone.png'),
  bgText: require('./bgText.png'),
  buttonIcon: require('./buttonIcon.png'),
  buttonIconActive: require('./buttonIconActive.png'),
  cardFront1: require('./card/cardFront1.png'),
  cardBack1: require('./card/cardBack1.png'),
  cardFront2: require('./card/cardFront2.png'),
  cardBack2: require('./card/cardBack2.png'),
  cardFront3: require('./card/cardFront3.png'),
  cardBack3: require('./card/cardBack3.png'),
  cardFront4: require('./card/cardFront4.png'),
  cardBack4: require('./card/cardBack4.png'),
  iconUp: require('./iconUp.png'),

  textBg: require('./textBg.png'),
  bgFish1: require('./background/bgFish1.svg').default,
  bgFish2: require('./background/bgFish2.svg').default,
  bgFish3: require('./background/bgFish3.svg').default,
  ship: require('./background/ship.svg').default,
  octopush: require('./background/octopush.svg').default,
  octopush2: require('./background/octopush2.svg').default,
  cloud: require('./cloud.png'),

  //text
  textTitle1: require('./text/textTitle1.png'),
  textTitle1Mobile: require('./text/textTitle1Mobile.png'),
  textTitle2: require('./text/textTitle2.png'),
  textTitle2Mobile: require('./text/textTitle2Mobile.png'),
  textTitle3: require('./text/textTitle3.png'),
  textTitle3Mobile: require('./text/textTitle3Mobile.png'),

  // music
  music: require('./lottie/music.json'),
  play: require('./play.svg').default,
  pause: require('./pause.svg').default,
}

export default images
